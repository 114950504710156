import education from './images/education.png';
import legal from './images/legal.png';
import graphic from './images/graphic.png';
import customerops from './images/customerops.png';
import finance from './images/finance.png';
import customer from './images/customer.png';
import writing from './images/writing.png';
import thech from './images/thech.png';
import sysadmin from './images/sysadmin.png';
import hr from './images/hr.png';
import management from './images/management.png';
import logistic from './images/logistic.png';
import photo from './images/photo.png';
import qa from './images/qa.png';
import soft from './images/soft.png';
import sales from './images/sales.png';

export const tiles = [
	{
		tileImage: sales,
		route: 'sales-marketing',
		tileTitle: 'Sales & Marketing',
		description:
			'GSS builds high-performing remote Sales & Marketing teams for your business, driving customer acquisition and revenue growth. We handle the hiring and talent management process, saving you time and resources while ensuring a perfect cultural fit.',
		positions: [
			'Google Tag Manager',
			'Account Manager',
			'Content Creator',
			'Sales Representative',
			'Account Executive',
			'Content Manager',
			'Marketing Manager',
			'Business Development Manager',
			'Sales Manager',
			'LinkedIn Campaign Manager',
			'Marketing Specialist',
			'Marketing Director',
			'CMO (Chief Marketing Officer)',
			'SEO Specialist',
			'Growth Hacker',
			'Digital Marketing Specialist',
			'Marketing Assistant',
			'Marketing Executive',
			'Sales Executive',
			'Digital Marketing Manager',
			'Sales Director',
		],
	},
	{
		tileImage: hr,
		route: 'hr',
		tileTitle: 'HR',
		description:
			'GSS specializes in sourcing exceptional HR professionals who excel in talent acquisition, employee engagement, and organizational development. Streamline your hiring process and access pre-vetted talent that fits your culture.',
		positions: [
			'Recruiter',
			'HR Generalist',
			'HR Manager',
			'HR Consultant',
			'Talent Acquisition Specialist',
			'HR Specialist',
			'HR Coordinator',
			'HR Business Partner',
			'HR Assistant',
			'HR Director',
			'HR Analyst',
			'Talent Acquisition Manager',
			'Recruitment Manager',
			'People Partner',
			'Talent Sourcer',
			'Talent Acquisition Lead',
			'Recruitment Lead',
		],
	},
	{
		tileImage: finance,
		route: 'finance-accounting',
		tileTitle: 'Finance & Accounting',
		description:
			"Optimize your financial operations with Global Staffing Support's skilled Finance and Accounting experts. Our recruitment process saves you time and resources, providing you with access to qualified professionals who can handle your financial needs with precision and efficiency.",
		positions: [
			'Accountant',
			'Chief Financial Officer (CFO)',
			'Auditor',
			'Bookkeeper',
			'Tax Accountant',
			'Finance Manager',
			'Payroll Specialist',
			'Financial Analyst',
			'Audit Manager',
			'Financial Risk Analyst',
			'SAP Specialist',
			'Exact Specialist',
		],
	},
	{
		tileImage: soft,
		route: 'software-development',
		tileTitle: 'Software Development',
		description:
			'Get your software projects off the ground with top-tier remote developers from GSS. We handle the hiring, so you can focus on building amazing products.',
		positions: [
			'Software Developer',
			'Full-Stack Developer',
			'Web Developer',
			'Cloud Engineer',
			'Game Developer',
			'Software Architect',
			'Technical Lead',
			'Shopify Developer',
			'Backend Developer',
			'Java Developer',
			'WordPress Developer',
			'Frontend Developer',
			'Python Developer',
			'.NET Developer',
			'Blockchain Developer',
			'PHP Developer',
			'React Developer',
			'Database Developer',
			'API Developer',
			'Drupal Developer',
			'GO Developer',
			'Golang Developer',
			'JavaScript Developer',
			'Hubspot Developer',
			'Mobile Developer',
			'Rust Developer',
			'Angular Developer',
			'Ruby on Rails Developer',
			'BigCommerce Developer',
			'C++ Developer',
			'Node Developer',
			'Joomla Developer',
			'Swift Developer',
			'Smart Contract Developer',
			'Django Developer',
			'CMS Developer',
			'Systems Developer',
			'Kotlin Developer',
			'Odoo Developer',
			'C Developer',
			'R Developer',
			'Scala Developer',
			'Low-Code Developer',
			'TypeScript Developer',
			'Vue.js Developer',
			'Elixir Developer',
			'AR/VR Developer',
			'Embedded Systems Developer',
			'Perl Developer',
			'Blockchain Security Engineer',
			'Blockchain Solution Architect',
		],
	},
	{
		tileImage: sysadmin,
		route: 'system-administration-devops-cloud',
		tileTitle: 'System Administration, DevOps & Cloud',
		description:
			'Get your IT systems running smoothly with top-tier System Administration, DevOps, and Cloud talent from GSS. We find the experts who keep your business online and secure.',
		positions: [
			'Site Reliability Engineer (SRE)',
			'Systems Engineer (DevOps focus)',
			'AWS Cloud Practitioner',
			'DevOps Engineer',
			'Cloud Engineer',
			'AWS Solutions Architect',
			'Network Administrator',
			'Cloud Architect',
			'Systems Administrator',
			'Platform Engineer',
			'Infrastructure Engineer',
			'Cloud Support Engineer',
			'Cloud Solutions Architect',
			'Cloud Consultant',
			'Cloud Administrator',
			'Security Administrator',
			'AWS DevOps Engineer',
			'GCP Data Engineer',
			'Cloud DevOps Engineer',
			'AWS SysOps Administrator',
			'Compliance Engineer',
			'Kubernetes Engineer',
			'Configuration Management Engineer',
			'AWS Security Specialist',
			'GCP Solutions Architect',
			'Cloud Migration Specialist',
			'GCP DevOps Engineer',
			'GCP Security Engineer',
			'Docker Engineer',
			'AWS Big Data Specialist',
			'AWS Machine Learning Specialist',
			'AWS Enterprise Account Manager',
		],
	},
	{
		tileImage: customerops,
		route: 'ai-ml-data-science',
		tileTitle: 'AI & ML & Data Science',
		description:
			'Unlock the power of AI, ML, and Data Science with top-tier talent from GSS. We find the pre-vetted experts who can transform your data into actionable insights and drive innovation.',
		positions: [
			'Data Scientist',
			'Data Analyst',
			'Data Engineer',
			'Robotics Engineer',
			'AI Engineer',
			'Data Architect',
			'AI Consultant',
			'ML Engineer',
			'AI Developer',
			'AI Product Manager',
			'ETL Developer',
			'Big Data Engineer',
			'Business Intelligence Engineer',
			'Computer Vision Engineer',
			'Natural Language Processing (NLP) Engineer',
			'AI Research Scientist',
			'Deep Learning Engineer',
			'AI Solution Architect',
			'Data Visualization Engineer',
			'ML Developer',
			'Prompt Manager',
		],
	},
	{
		tileImage: qa,
		route: 'qa',
		tileTitle: 'QA',
		description:
			"Ensure flawless software with GSS's remote Quality Assurance experts. We connect you with top QA talent who can help you deliver high-quality products efficiently. We handle the hiring process, saving you valuable time and resources.",
		positions: [
			'QA Engineer',
			'Software Tester',
			'QA Analyst',
			'Usability Tester',
			'Automation Tester',
			'Performance Tester',
			'Security Tester',
			'Manual Tester',
			'QA Manager',
			'QA Specialist',
			'QA Automation Engineer',
			'Integration Tester',
			'QA Game Tester',
			'Test Automation Engineer',
			'QA Consultant',
			'Software Engineer in Test',
			'QA Lead',
		],
	},
	{
		tileImage: thech,
		route: 'technical-support',
		tileTitle: 'Technical Support',
		description:
			'GSS can enhance user satisfaction by finding you pre-vetted Technical Support talent. Let us handle the hiring and talent management to save you time and resources.',
		positions: [
			'IT Support Specialist',
			'Help Desk Technician',
			'IT Support Technician',
			'Service Desk Analyst',
			'IT Operations Manager',
			'IT Helpdesk Analyst',
		],
	},
	{
		tileImage: graphic,
		route: 'graphic-design',
		tileTitle: 'Graphic Design',
		description:
			'Bring your brand vision to life with top-tier remote Graphic & Design professionals from GSS. We find the creative professionals who can craft stunning visuals and elevate your brand without you having to worry over exhausting hiring and management process.',
		positions: [
			'Illustrator',
			'Graphic Designer',
			'Logo Designer',
			'Web Designer',
			'Creative Director',
			'UI/UX Designer',
			'Art Director',
			'Motion Graphics Designer',
			'Game Designer',
			'Character Designer',
			'3D Artist',
			'Visual Designer',
			'Character Artist',
			'Game Illustrator',
			'Gameplay Animator',
		],
	},
	{
		tileImage: photo,
		route: 'photo-video',
		tileTitle: 'Photo & Video',
		description:
			'GSS connects you with pre-vetted remote Photo and Video professionals who can bring your stories to life, saving you time and resources.',
		positions: [
			'Photographer',
			'Photo Editor',
			'Video Editor',
			'Videographer',
			'Animator',
			'Layout Artist',
			'VFX Editor',
			'Texture Artist',
			'Modeling Artist',
		],
	},
	{
		tileImage: writing,
		route: 'writing-translation',
		tileTitle: 'Writing & Translation',
		description:
			'GSS connects you with Writing and Translation experts. Not only hiring, GSS also handles all the management process, saving you time and resources for up to 50%.',
		positions: [
			'Translator',
			'Copywriter',
			'Proofreader',
			'Editor',
			'Technical Writer',
			'Content Writer',
			'Scriptwriter',
			'Creative Writer',
		],
	},
	{
		tileImage: legal,
		route: 'legal',
		tileTitle: 'Legal',
		description:
			'GSS helps you navigate legal complexities through remote Legal professionals. Let us handle all the hiring and management processes so you can save more time and resources.',
		positions: [
			'Lawyer',
			'Legal Assistant',
			'Tax Lawyer',
			'Legal Counsel',
			'Compliance Officer',
			'Legal Consultant',
			'Contract Specialist',
			'Legal Advisor',
			'Legal Analyst',
			'Legal Researcher',
			'Intellectual Property (IP) Advisor',
		],
	},
	{
		tileImage: logistic,
		route: 'logistics',
		tileTitle: 'Logistics',
		description:
			'Find the top Logistics Specialists through GSS. We handle all the hiring and management processes so you can focus more on productivity.',
		positions: [
			'Logistics Coordinator',
			'Procurement Specialist',
			'Procurement Manager',
			'Logistics Analyst',
			'Supply Chain Manager',
			'Procurement Analyst',
			'SAP Consultant',
			'Distribution Manager',
		],
	},
	{
		tileImage: customer,
		route: 'customer-support-ops-vas',
		tileTitle: 'Customer Support, Ops & VAs',
		description:
			"Optimize your customer care through GSS' pre-vetted Customer Agents. Sit back while we handle all the hiring and talent management process.",
		positions: [
			'Customer Service Representative',
			'Client Success Manager',
			'Call Center Agent',
			'Customer Experience Specialist',
			'Service Desk Analyst',
			'Customer Success Specialist',
			'Client Service Manager',
			'Client Support Specialist',
			'Customer Support Manager',
			'Client Relations Manager',
			'Support Operations Manager',
			'Customer Support Coordinator',
			'Customer Support Supervisor',
			'Customer Support Team Lead',
		],
	},
	{
		tileImage: education,
		route: 'education-elearning',
		tileTitle: 'Education & eLearning',
		description:
			'GSS helps you connect with Education and E-learning professionals through our end-to-end services. From hiring to talent management, we handle them all so you can save time and resources.',
		positions: [
			'Instructor',
			'Education Consultant',
			'Online Tutor',
			'Corporate Trainer',
			'Education Specialist',
			'Training Manager',
			'Training Coordinator',
			'Online Course Creator',
			'Learning Experience Designer',
			'Educational Content Writer',
			'Internship Advisor',
		],
	},
	{
		tileImage: management,
		route: 'management-operations',
		tileTitle: 'Management & Operations',
		description:
			'GSS can connect you to global Management and Operations talent, giving you access to the best talents available while saving you time and resources up to 50%.',
		positions: [
			'COO',
			'SCRUM Master',
			'Business Analyst',
			'Project Manager',
			'Product Manager',
			'Operations Manager',
			'Business Consultant',
			'Project Coordinator',
			'Product Owner',
			'Program Coordinator',
			'Operations Analyst',
			'Portfolio Manager',
			'Operations Specialist',
			'Operations Coordinator',
			'Technical Product Manager',
			'Technical Project Manager',
			'Project Risk Manager',
			'RevOps',
			'Digital Product Manager',
			'Delivery Manager',
			'Agile Project Manager',
			'Revenue Manager',
			'Business Unit Manager',
		],
	},
];
