import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import { MainButton } from '../MainButton/MainButton';

import OutsideClickHandler from 'react-outside-click-handler';

import { ReactComponent as MenuIcon } from '../../Components/Icons/newMenu.svg';

import motto from './images/motto.png';
import logo from './images/logo.png';

import './Header.scss';
import { Menu } from './Components/Menu/Menu';
import { tiles } from '../../pages_v2/HireStuff/data';

export default function Header() {
	const [hireStaff, setHireStaff] = useState(false);

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<>
			<div className="header">
				<div className="headerMotto">
					<div
						className="mottoImage"
						style={{
							backgroundImage: `url(${motto})`,
						}}
					/>
				</div>
				<div className="newHeaderContent">
					<div className="newHeaderContentWrapper">
						<NavLink to="/" className="logoLinkWrapper">
							<div
								className="logoImage"
								style={{
									backgroundImage: `url(${logo})`,
								}}
							/>
						</NavLink>

						<div className="newHeaderMenuWrapper">
							<div className="topLink">
								<div className="hireStaffLinkWrapper">
									<NavLink
										to="/hireStuff"
										onMouseEnter={() => setHireStaff(true)}
										className="headerTopLink"
										activeClassName="activeHeaderLink"
									>
										Hire Staff
									</NavLink>
									{hireStaff && (
										<OutsideClickHandler onOutsideClick={() => setHireStaff(false)}>
											<div onMouseLeave={() => setHireStaff(false)} className="hireStaffContainer">
												{tiles.map((tile, index) => (
													<NavLink to={`/hireStuffDetails/${tile.route}`} key={index} className="hireStaffLink">
														<div
															className="hireStaffImage"
															style={{
																backgroundImage: `url(${tile.tileImage})`,
															}}
														/>
														<span>{tile.tileTitle}</span>
													</NavLink>
												))}
											</div>
										</OutsideClickHandler>
									)}
								</div>

								<NavLink to="/whyus" className="headerTopLink" activeClassName="activeHeaderLink">
									Why Us
								</NavLink>
								{/* <NavLink to="/v2/remotestaffing" className="headerTopLink" activeClassName="activeHeaderLink">
									Why Remote Staffing
								</NavLink> */}
								<NavLink to="/successstories" className="headerTopLink" activeClassName="activeHeaderLink">
									Success Stories
								</NavLink>
								{/* <NavLink to="/v2/jobopening" className="headerTopLink" activeClassName="activeHeaderLink">
									Job Openings
								</NavLink> */}
							</div>
							<div className="bottomLinks">
								<NavLink exact to="/" className="newHeaderLink" activeClassName="activeHeaderLink">
									Home
								</NavLink>
								{/* <NavLink to="/v2/blog" className="newHeaderLink" activeClassName="activeHeaderLink">
									Blog
								</NavLink> */}
								{/* <NavLink to="/v2/event" className="newHeaderLink" activeClassName="activeHeaderLink">
									Events
								</NavLink> */}
								<NavLink to="/aboutus" className="newHeaderLink" activeClassName="activeHeaderLink">
									About Us
								</NavLink>
								<NavLink to="/contactus" className="newHeaderLink" activeClassName="activeHeaderLink">
									Contact Us
								</NavLink>
							</div>
						</div>
					</div>
					<div className="headerMainButtonsWrapper">
						<MainButton text="START HIRING" filled={true} route="startHiring" />
						<MainButton text="GET HIRED" route="/gethired" />
					</div>
					<div className="headerMenu" onClick={() => setMenuOpen(true)}>
						<MenuIcon />
					</div>
				</div>
			</div>
			<Menu menuOpened={menuOpen} setMenuOpened={setMenuOpen} />
		</>
	);
}
